import { useEffect } from "react";
import { getMenuData, getMarqueeData } from "../store/slices/HeaderDataSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHeader = () => {
  const dispatch = useDispatch();
  const { menu, marquee, status } = useSelector((state) => state.header);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getMenuData());
      dispatch(getMarqueeData());
    }
  }, []);

  return {
    menu,
    marquee,
  };
};
