import { useEffect } from "react";
import {
  getFooterCommonDetailsData,
  getFooterMenuData,
} from "../store/slices/FooterDataSlice";
import { useDispatch, useSelector } from "react-redux";

export const useFooter = () => {
  const dispatch = useDispatch();
  const { footer_menu, footer_commondetails, status } = useSelector(
    (state) => state.footer
  );
  useEffect(() => {
    if (status === "idle") {
      dispatch(getFooterCommonDetailsData());
      dispatch(getFooterMenuData());
    }
  }, []);
  const footerContent =
    footer_commondetails?.footer_main_content?.[0]?.footer_main_content;

  return {
    footer_menu,
    footer_commondetails,
    footerContent
  };
};
