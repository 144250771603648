import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  aboutUsStatus: "idle",
  whoWeAre: null,
  mission: null,
  ourTeamSingle: null,
  ourTeamGroup: null,
};

export const getWhoWeAreData = createAsyncThunk(
  "aboutus/whoweare",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "/about/brokerage-distribution"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMissionData = createAsyncThunk(
  "aboutus/mission",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/about/core-values");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOurTeamSingleData = createAsyncThunk(
  "aboutus/ourteamsingle",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/about/ceo-message");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOurTeamGroupData = createAsyncThunk(
  "aboutus/ourteamgroup",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/about/our-team");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {},
  extraReducers: {
    [getWhoWeAreData.pending]: (state) => {
      state.aboutUsStatus = "loading";
      state.message = null;
    },
    [getWhoWeAreData.fulfilled]: (state, action) => {
      state.aboutUsStatus = "succeeded";
      state.whoWeAre =
        action?.payload?.data?.data?.details?.brokerage_distribution;
    },
    [getWhoWeAreData.rejected]: (state) => {
      state.aboutUsStatus = "failed";
    },

    [getMissionData.pending]: (state) => {
      state.aboutUsStatus = "loading";
      state.message = null;
    },
    [getMissionData.fulfilled]: (state, action) => {
      state.aboutUsStatus = "succeeded";
      state.mission = action?.payload?.data?.data?.details?.core_values;
    },
    [getMissionData.rejected]: (state) => {
      state.aboutUsStatus = "failed";
    },

    [getOurTeamSingleData.pending]: (state) => {
      state.aboutUsStatus = "loading";
      state.message = null;
    },
    [getOurTeamSingleData.fulfilled]: (state, action) => {
      state.aboutUsStatus = "succeeded";
      state.ourTeamSingle = action?.payload?.data?.data?.details?.ceo_message;
    },
    [getOurTeamSingleData.rejected]: (state) => {
      state.aboutUsStatus = "failed";
    },

    [getOurTeamGroupData.pending]: (state) => {
      state.aboutUsStatus = "loading";
      state.message = null;
    },
    [getOurTeamGroupData.fulfilled]: (state, action) => {
      state.aboutUsStatus = "succeeded";
      state.ourTeamGroup = action?.payload?.data?.data?.details?.our_team;
    },
    [getOurTeamGroupData.rejected]: (state) => {
      state.aboutUsStatus = "failed";
    },
  },
});

export default getAboutUsSlice.reducer;
