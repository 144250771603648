import {
  getMissionData,
  getOurTeamGroupData,
  getOurTeamSingleData,
  getWhoWeAreData,
} from "../store/slices/AboutUsSlice";
import {
  getContactDetailsData,
  getFAQListingData,
} from "../store/slices/ContactUsSlice";
import { getAttentionData } from "../store/slices/AttentionInvestersSlice";
import {
  getAMLPolicyData,
  getGeneralDisclaimerData,
  getGeneralTermsData,
  getPoliciesData,
  getPrivacyPolicyData,
  getServiceTermsData,
} from "../store/slices/TermsAndPolicySlice";
import { getProductsData } from "../store/slices/ProductsSlice";

import { useDispatch, useSelector } from "react-redux";
import { getPlatformData } from "../store/slices/PlatformSlice";

export const useGetHoverData = () => {
  const dispatch = useDispatch();

  const { aboutUsStatus } = useSelector((state) => state.aboutUs);
  const { contactUsStatus } = useSelector((state) => state.contactUs);
  const { attentionStatus } = useSelector((state) => state.attention);
  const { platformStatus } = useSelector((state) => state.platform);

  const fetchOnMouseHover = (slug) => {
    switch (slug) {
      case "/about-us":
        if (aboutUsStatus === "idle") {
          dispatch(getMissionData());
          dispatch(getOurTeamSingleData());
          dispatch(getOurTeamGroupData());
          dispatch(getWhoWeAreData());
        }
        break;
      case "/contact-us":
        if (contactUsStatus === "idle") {
          dispatch(getContactDetailsData());
          dispatch(getFAQListingData());
        }
        break;

      case "/attention-investors":
        if (attentionStatus === "idle") {
          dispatch(getAttentionData());
        }
        break;
      case "/our-platforms":
        if (platformStatus === "idle") {
          dispatch(getPlatformData());
        }
        break;
    }
  };

  return {
    fetchOnMouseHover,
  };
};
