import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  kycBannerData: {},
  kycClientRegData: {},
};

export const getKycPolicyBannerData = createAsyncThunk(
  "kycpolicy/banner",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/kyc-policies/kyc-Policies-banner");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getKycPolicyClientRegData = createAsyncThunk(
  "kycpolicy/clientreg",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/kyc-policies/registration-documents");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const kycPolicySlice = createSlice({
  name: "kycpolicy",
  initialState,
  reducers: {},
  extraReducers: {
    [getKycPolicyBannerData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getKycPolicyBannerData.fulfilled]: (state, action) => {
      state.status = "succeeded";
     state.kycBannerData = action.payload.data?.data?.details;
    },
    [getKycPolicyBannerData.rejected]: (state) => {
      state.status = "failed";
    },
    
    [getKycPolicyClientRegData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getKycPolicyClientRegData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.kycClientRegData = action.payload.data?.data?.details;
    },
    [getKycPolicyClientRegData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default kycPolicySlice.reducer