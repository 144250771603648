import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  attentionStatus: "idle",
  attention: null,
};

export const getAttentionData = createAsyncThunk(
  "attention",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("attention-investors");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAttentionSlice = createSlice({
  name: "attention",
  initialState,
  reducers: {},
  extraReducers: {
    [getAttentionData.pending]: (state) => {
      state.attentionStatus = "loading";
      state.message = null;
    },
    [getAttentionData.fulfilled]: (state, action) => {
      state.attentionStatus = "succeeded";
      state.attention =
        action?.payload?.data?.data?.details?.attention_investors;
    },
    [getAttentionData.rejected]: (state) => {
      state.attentionStatus = "failed";
    },
  },
});

export default getAttentionSlice.reducer;
