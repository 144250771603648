import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  termsStatus: "idle",
  Scores:null,
  AMLPolicy: null,
  ServiceTerms: null,
  GeneralTerms: null,
  PrivacyPolicy: null,
  GeneralDisclaimer: null,
  Policies: null,
};

export const getAMLPolicyData = createAsyncThunk(
  "terms/AMLPolicy",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("page?slug=aml-policy");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScroesData = createAsyncThunk(
  "terms/Scores",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("page?slug=scores");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getServiceTermsData = createAsyncThunk(
  "terms/ServiceTerms",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "page?slug=terms-conditions"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getGeneralTermsData = createAsyncThunk(
  "terms/GeneralTerms",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "page?slug=general-terms-and-conditions"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPrivacyPolicyData = createAsyncThunk(
  "terms/PrivacyPolicy",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("page?slug=privacy-policy");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getGeneralDisclaimerData = createAsyncThunk(
  "terms/GeneralDisclaimer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "page?slug=general-disclaimer"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPoliciesData = createAsyncThunk(
  "terms/Policies",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "page?slug=policies-and-procedure"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getTermsAndPolicySlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: {
    [getAMLPolicyData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getAMLPolicyData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.AMLPolicy = action?.payload?.data?.data?.details;
    },
    [getAMLPolicyData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getScroesData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getScroesData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.Scores = action?.payload?.data?.data?.details;
    },
    [getScroesData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getServiceTermsData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getServiceTermsData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.ServiceTerms = action?.payload?.data?.data?.details;
    },
    [getServiceTermsData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getGeneralTermsData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getGeneralTermsData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.GeneralTerms = action?.payload?.data?.data?.details;
    },
    [getGeneralTermsData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getPrivacyPolicyData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getPrivacyPolicyData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.PrivacyPolicy = action?.payload?.data?.data?.details;
    },
    [getPrivacyPolicyData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getGeneralDisclaimerData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getGeneralDisclaimerData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.GeneralDisclaimer = action?.payload?.data?.data?.details;
    },
    [getGeneralDisclaimerData.rejected]: (state) => {
      state.termsStatus = "failed";
    },

    [getPoliciesData.pending]: (state) => {
      state.termsStatus = "loading";
      state.message = null;
    },
    [getPoliciesData.fulfilled]: (state, action) => {
      state.termsStatus = "succeeded";
      state.Policies = action?.payload?.data?.data?.details;
    },
    [getPoliciesData.rejected]: (state) => {
      state.termsStatus = "failed";
    },
  },
});

export default getTermsAndPolicySlice.reducer;
