import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  contactUsStatus: "idle",
  contactDetails: null,
  FAQListing: null,
};

export const getContactDetailsData = createAsyncThunk(
  "contactUs/contactListing",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/contact/contact-details");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFAQListingData = createAsyncThunk(
  "contactUs/FAQListing",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/contact/contact-faqs");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getContactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: {
    [getContactDetailsData.pending]: (state) => {
      state.contactUsStatus = "loading";
      state.message = null;
    },
    [getContactDetailsData.fulfilled]: (state, action) => {
      state.contactUsStatus = "succeeded";
      state.contactDetails = action.payload?.data?.data?.details;
    },
    [getContactDetailsData.rejected]: (state) => {
      state.contactUsStatus = "failed";
    },

    [getFAQListingData.pending]: (state) => {
      state.contactUsStatus = "loading";
      state.message = null;
    },
    [getFAQListingData.fulfilled]: (state, action) => {
      state.contactUsStatus = "succeeded";
      state.FAQListing = action.payload?.data?.data?.details?.contact_faq;
    },
    [getFAQListingData.rejected]: (state) => {
      state.contactUsStatus = "failed";
    },
  },
});

export default getContactUsSlice.reducer;
