import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  singleBlog: null,
};

export const getSingleBlogData = createAsyncThunk(
  "singleBlog",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        `/blog/single?slug=${params}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getSingleBlogSlice = createSlice({
  name: "singleBlog",
  initialState,
  reducers: {},
  extraReducers: {
    [getSingleBlogData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getSingleBlogData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleBlog = action.payload?.data?.data?.details;
    },
    [getSingleBlogData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getSingleBlogSlice.reducer;
