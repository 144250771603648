import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  productsStatus: "idle",
  products: {},
  selectedProduct: "",
};

export const getProductsData = createAsyncThunk(
  "productDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(`/product?slug=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    currentSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: {
    [getProductsData.pending]: (state) => {
      state.productsStatus = "loading";
      state.message = null;
    },
    [getProductsData.fulfilled]: (state, action) => {
      state.productsStatus = "succeeded";
      state.products[state.selectedProduct] =
        action?.payload?.data?.data?.details?.[0]?.page_sections;
    },
    [getProductsData.rejected]: (state) => {
      state.productsStatus = "failed";
    },
  },
});

export const { currentSelectedProduct } = getProductsSlice.actions;
export default getProductsSlice.reducer;
