import {
  getProductsData,
  currentSelectedProduct,
} from "../store/slices/ProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useProducts = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { products, productsStatus, selectedProduct } = useSelector(
    (state) => state.products
  );
  useEffect(() => {
    if (productsStatus === "idle") {
      location.pathname !== "/" && dispatch(getProductsData(location.pathname));
      dispatch(currentSelectedProduct(location.pathname.substring(1)));
    }
  }, [location.pathname]);

  const getProductItem = (value) => {
    if (products[value]?.banner_field_set == undefined)
      dispatch(getProductsData(value));
  };

  return {
    products,
    selectedProduct,
    getProductItem,
  };
};
