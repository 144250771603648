import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  mutualFundData: {},
};

export const getMutualFund = createAsyncThunk(
  "mutualfund",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/mutual-funds-offline");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const mutualFundSlice = createSlice({
  name: "mutualfund",
  initialState,
  reducers: {},
  extraReducers: {
    [getMutualFund.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getMutualFund.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.mutualFundData = action.payload.data?.data?.details;
    },
    [getMutualFund.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default mutualFundSlice.reducer;
