import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  blogsCategory: [],
  blogsList: {},
  selectedBlogCategory: "all",
  blogIndex: 0,
  currentPage: 1,
};

export const getblogsCategoryData = createAsyncThunk(
  "blogs/getblogsCategoryData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/blog/categories");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBlogsListData = createAsyncThunk(
  "blogs/getBlogsListData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "/blogs?page=1&posts_per_page=6"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const filteredCategoryList = createAsyncThunk(
  "blogs/filteredCategoryList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(`/blogs?cat=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMore = createAsyncThunk(
  "blogs/LoadMore",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        `/blogs?page=${params}&posts_per_page=6`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getBlogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    currentSelectedCategory: (state, action) => {
      state.selectedBlogCategory = action.payload;
    },
    currentBlogIndex: (state, action) => {
      state.blogIndex = action.payload;
    },
  },
  extraReducers: {
    [getblogsCategoryData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getblogsCategoryData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogsCategory = action.payload?.data?.data?.details;
    },
    [getblogsCategoryData.rejected]: (state) => {
      state.status = "failed";
    },

    [getBlogsListData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getBlogsListData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogsList[state.selectedBlogCategory] =
        action.payload?.data?.data?.details;
    },
    [getBlogsListData.rejected]: (state) => {
      state.status = "failed";
    },

    [filteredCategoryList.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [filteredCategoryList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogsList[state.selectedBlogCategory] =
        action.payload?.data?.data?.details;
    },
    [filteredCategoryList.rejected]: (state) => {
      state.status = "failed";
    },

    [loadMore.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [loadMore.fulfilled]: (state, action) => {
      state.status = "succeeded";

      state.blogsList[state.selectedBlogCategory].blog_list = [
        ...state.blogsList[state.selectedBlogCategory].blog_list,
        ...action.payload?.data?.data?.details.blog_list,
      ];

      state.blogsList[state.selectedBlogCategory].more_details = {
        ...state.blogsList[state.selectedBlogCategory].more_details,
        current_page:
          action.payload?.data?.data?.details?.more_details?.current_page,
      };
    },
    [loadMore.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export const { currentSelectedCategory } = getBlogsSlice.actions;
export const { currentBlogIndex } = getBlogsSlice.actions;
export const { currentPage } = getBlogsSlice.actions;
export default getBlogsSlice.reducer;
