import React from "react";
import { Link } from "react-router-dom";

import Style from "./FooterDesktopNav.module.scss";
import { useGetHoverData } from "../../../../logic/useGetHoverData";
import { currentSelectedProduct } from "../../../../store/slices/ProductsSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const FooterDesktopNav = ({ footer_menu }) => {
  const location = useLocation();
  const { fetchOnMouseHover } = useGetHoverData();
  const dispatch = useDispatch();
  const parse = require("html-react-parser");
  return (
    <>
      <div className={`${Style.site_footer_products} d-none d-md-block`}>
        <div className={Style.site_footer_nav_title}>Products:</div>
        <ul>
          {footer_menu?.products_menu?.map((value, index) => {
            return (
              <li
                key={index}
                className={
                  location.pathname.substring(1) === value?.slug
                    ? Style.active
                    : ""
                }
              >
                <Link
                  to={`/${value?.slug}`}
                  onClick={() =>
                    dispatch(currentSelectedProduct(`${value?.slug}`))
                  }
                >
                  {parse(value?.title)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`${Style.site_footer_platforms} d-none d-md-block`}>
        <div className={Style.site_footer_nav_title}>Platforms</div>
        <ul>
          {footer_menu?.platform_menu?.map((value, index) => (
            <li key={index}>
              {value?.url == "/platforms" ? (
                <Link to="/platforms"  state={{ from: "penapi" }} >
                  {value?.title}
                </Link>
              ) : (
                <a href={value?.url} target={value?.target}>
                  {value?.title}
                </a>
              )} 
            </li>
          ))}
        </ul>
      </div>
      <div className={`${Style.site_footer_quik_links} d-none d-md-block`}>
        <div className={Style.site_footer_nav_title}>Quick Links</div>
        <ul>
          {footer_menu?.quick_links?.map((value, index) => {
            return (
              <li
                key={index}
                className={
                  location.pathname.substring(1) === value?.slug
                    ? Style.active
                    : ""
                }
              >
                {value?.target == "_blank" ? (
                  <a href={value?.url} target={value?.target}>
                    {parse(value?.title)}
                  </a>
                ) : (
                  <Link to={`/${value?.slug}`}>{parse(value?.title)}</Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`${Style.site_footer_legal} d-none d-md-block`}>
        <div className={Style.site_footer_nav_title}>Legal</div>
        <ul>
          {footer_menu?.legal_menu?.map((value, index) => {
            return (
              <li
                key={index}
                className={
                  location.pathname.substring(1) === value?.slug
                    ? Style.active
                    : ""
                }
              >
                <Link
                  onMouseEnter={() => {
                    fetchOnMouseHover(`/${value?.slug}`);
                  }}
                  to={`/${value?.slug}`}
                >
                  {parse(value?.title)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`${Style.site_footer_useful_links} d-none d-md-block`}>
        <div className={Style.site_footer_nav_title}>Useful Links</div>
        <ul>
          {footer_menu?.useful_links?.map((value, index) => {
            return (
              <li
                key={index}
                className={
                  location.pathname.substring(1) === value?.slug
                    ? Style.active
                    : ""
                }
              >
                <a href={value?.url} target={value?.target}>
                  {parse(value?.title)}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default FooterDesktopNav;
