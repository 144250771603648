import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  faq: null,
};

export const getFAQData = createAsyncThunk(
  "faq",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/faq");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getFAQSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: {
    [getFAQData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getFAQData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.faq = action.payload?.data?.data?.details;
    },
    [getFAQData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getFAQSlice.reducer;
