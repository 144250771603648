import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  platformStatus: "idle",
  platform: null,
};

export const getPlatformData = createAsyncThunk(
  "platform",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/platform");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getPlatformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {},
  extraReducers: {
    [getPlatformData.pending]: (state) => {
      state.platformStatus = "loading";
      state.message = null;
    },
    [getPlatformData.fulfilled]: (state, action) => {
      state.platformStatus = "succeeded";
      state.platform = action?.payload?.data?.data?.details?.platform_details;
    },
    [getPlatformData.rejected]: (state) => {
      state.platformStatus = "failed";
    },
  },
});

export default getPlatformSlice.reducer;
