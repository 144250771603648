import siteLogo from "../../assets/main_logo.svg";
import dummy_image from "../../assets/dummy_image.jpg";
import member_broker from "../../assets/member_broker.jpg";
import choose_from from "../../assets/choose_from.jpg";
import recentblog1 from "../../assets/recentblog1.jpg";
import recentblog2 from "../../assets/recentblog2.jpg";
import recentblog3 from "../../assets/recentblog3.webp";
import home_banner from '../../assets/home/banner/home-banner.webp';
import products_future_img from '../../assets/home/products/future-and-option-img.png';
import pdf_icon from '../../assets/download/pdf_icon.png';

/* Home Products */
import prodcuts_icon_01 from '../../assets/home/products/icons/equity.svg'
import prodcuts_icon_02 from '../../assets/home/products/icons/mutual-funds.svg'
import prodcuts_icon_03 from '../../assets/home/products/icons/future-and-option.svg'
import prodcuts_icon_04 from '../../assets/home/products/icons/fixed-deposit.svg'
import prodcuts_icon_05 from '../../assets/home/products/icons/bonds.svg'
import prodcuts_icon_06 from '../../assets/home/products/icons/global-investing.svg'
import prodcuts_icon_07 from '../../assets/home/products/icons/equity.svg'
import prodcuts_icon_08 from '../../assets/home/products/icons/investing-with.svg';
import prodcuts_icon_09 from '../../assets/home/products/icons/exchange-traded-funds.svg'
import platform_mac from "../../assets/home/platform/platform-mac.png";

import pen_logo from "../../assets/home/platform/pen-logo.svg";
import pen_logo_2 from "../../assets/home/platform/Pen_Logo.svg";
import book_logo from "../../assets/home/platform/book_logo.svg";
import box from "../../assets/home/platform/Box.svg";
import mutual_fund_logo from "../../assets/home/platform/mutual_fund_logo.svg";
import global_investing_logo from "../../assets/home/platform/global_investing_logo.svg";
import evoting_icon from '../../assets/platforms/evoting_icon.svg';

import scan_qr from "../../assets/home/platform/scan-qr.png";
import google_play from "../../assets/home/platform/google-play.png";
import app_store from "../../assets/home/platform/app-store.png";

/* Why Pentad */
import pentad_icon_1 from "../../assets/home/whypentad/icon1.svg";
import pentad_icon_2 from "../../assets/home/whypentad/icon2.svg";
import pentad_icon_3 from "../../assets/home/whypentad/icon3.svg";
import pentad_icon_4 from "../../assets/home/whypentad/icon4.svg";

/* Member Broker */
import member_broker_01 from '../../assets/home/member-broker/logo_01.png'
import member_broker_02 from '../../assets/home/member-broker/logo_02.png'
import member_broker_03 from '../../assets/home/member-broker/logo_03.png'
import member_broker_04 from '../../assets/home/member-broker/logo_04.png'
import member_broker_05 from '../../assets/home/member-broker/logo_05.png'
import member_broker_06 from '../../assets/home/member-broker/logo_06.png'

// contact Page

import contactimg_1 from '../../assets/contact/contactform/contactimg1.svg';

import coming_soon from '../../assets/coming_soon.svg';

import investor_grevience_image from '../../assets/investor-grevience/investor_grevience_image.svg';

// Blog Page

import blog_img_1 from '../../assets/blog/blog_img_1.png';
import blog_img_2 from '../../assets/blog/blog_img_3.png';
import blog_img_3 from '../../assets/blog/blog_img_4.png';
import blog_img_4 from '../../assets/blog/blog_img_5.png';
import blog_img_5 from '../../assets/blog/blog_img_6.png';
import blog_img_6 from '../../assets/blog/blog_img_2.png';

// //Blog Detail Page

import blogdetail_img_1 from '../../assets/blog-detail/blogdetail_img_1.png';

// About 
import icon_mission from '../../assets/about/our-core-values/icon_mission.svg';
import icon_mission_webp from '../../assets/about/our-core-values/icon_mission.webp';
import icon_vision from '../../assets/about/our-core-values/icon_vision.svg';
import icon_vision_webp from '../../assets/about/our-core-values/icon_vision.webp';
import icon_values from '../../assets/about/our-core-values/icon_values.svg';
import icon_values_webp from '../../assets/about/our-core-values/icon_values.webp';
import qoute_icon from '../../assets/qoute_icon.svg';

import team_ceo from "../../assets/about/our-team/team_ceo.jpg"
import team_img_01 from '../../assets/about/our-team/team_img_01.jpg'
import team_img_02 from '../../assets/about/our-team/team_img_02.jpg';

import error_img from '../../assets/error/error_img.svg';

// Registered Advisors
import intro_img from '../../assets/products/registered-advisors/intro_img.png';
import member_1 from '../../assets/products/registered-advisors/member_1.png';
import member_2 from '../../assets/products/registered-advisors/member_2.png';
import products_banner from '../../assets/products/banner/products_banner.png';
import open_account_img_01 from '../../assets/products/open-account/open_account_img_01.svg';
import open_account_img_02 from '../../assets/products/open-account/open_account_img_02.svg';

// Mutual Funds
import mutual_icon_1 from '../../assets/products/mutual-funds/mutual_icon_1.svg';
import mutual_icon_2 from '../../assets/products/mutual-funds/mutual_icon_2.svg';
import mutual_icon_3 from '../../assets/products/mutual-funds/mutual_icon_3.svg';
import mutual_icon_4 from '../../assets/products/mutual-funds/mutual_icon_4.svg';
import mutual_icon_5 from '../../assets/products/mutual-funds/mutual_icon_5.svg';
import mutal_benefit from '../../assets/products/mutual-funds/mutal_benefit.png';

//  Equity
import equity_stock from '../../assets/products/equity/equity_stock.png';
import feature_icon_1 from '../../assets/products/equity/feature_icon_1.svg';
import feature_icon_2 from '../../assets/products/equity/feature_icon_2.svg';
import feature_icon_3 from '../../assets/products/equity/feature_icon_3.svg';

// Exchange Trade

import bees_icon_1 from '../../assets/products/exchange-trade/bees_icon_1.svg';
import bees_icon_2 from '../../assets/products/exchange-trade/bees_icon_2.svg';
import bees_icon_3 from '../../assets/products/exchange-trade/bees_icon_3.svg';

// Global Investing 

import invest_icon_1 from '../../assets/products/global-investing/invest_icon_1.svg';
import invest_icon_2 from '../../assets/products/global-investing/invest_icon_2.svg';

import platform_img_01 from '../../assets/platforms/platform_img_01.png';

import store_qr from '../../assets/platforms/store/store_qr.png';
import apple_store from '../../assets/platforms/store/apple_store.svg';
import play_store from '../../assets/platforms/store/play_store.svg';
// Future and Option

import futureoption_1 from '../../assets/products/future-option/futureoption_1.svg';
import futureoption_2 from '../../assets/products/future-option/futureoption_2.svg';
import futureoption_3 from '../../assets/products/future-option/futureoption_3.svg';
import futureoption_4 from '../../assets/products/future-option/futureoption_4.svg';

// NRI Invsetment
import nri_banner from '../../assets/nri-investment/nri_banner.svg'

//Attention Investers

import ap_pdf1 from '../../assets/attention-investers/pdf/BSE AP LIST UPDATE.pdf';
import ap_pdf2 from '../../assets/attention-investers/pdf/MCX AP LIST UPDATE.pdf';
import ap_pdf3 from '../../assets/attention-investers/pdf/NSE AP LIST UPDATE.pdf';

//Open an Account 

import mutualfund_banner from '../../assets/open-account/banner/mutualfund-banner@2x.png';
import mutualfund_banner_webp from '../../assets/open-account/banner/mutualfund-banner.webp';
import mutual_bg from '../../assets/open-account/banner/mutual_bg.png';


const Assets = {
  dummy_image,
  siteLogo,
  member_broker,
  choose_from,
  recentblog1,
  recentblog2,
  recentblog3,
  blog_img_1,
  blog_img_2,
  blog_img_3,
  blog_img_4,
  blog_img_5,
  blog_img_6,
  blogdetail_img_1,
  home_banner,
  products_future_img,
  prodcuts_icon_01,
  prodcuts_icon_02,
  prodcuts_icon_03,
  prodcuts_icon_04,
  prodcuts_icon_05,
  prodcuts_icon_06,
  prodcuts_icon_07,
  prodcuts_icon_08,
  prodcuts_icon_09,
  platform_mac,
  pen_logo,
  pen_logo_2,
  box,
  scan_qr,
  google_play,
  app_store,
  member_broker_01,
  member_broker_02,
  member_broker_03,
  member_broker_04,
  member_broker_05,
  member_broker_06,
  pentad_icon_1,
  pentad_icon_2,
  pentad_icon_3,
  pentad_icon_4,
  contactimg_1,
  icon_mission,
  icon_mission_webp,
  icon_vision,
  icon_vision_webp,
  icon_values,
  icon_values_webp,
  team_ceo,
  team_img_01,
  team_img_02,
  pdf_icon,
  qoute_icon,
  error_img,
  intro_img,
  member_1,
  member_2,
  products_banner,
  open_account_img_01,
  open_account_img_02,
  mutual_icon_1,
  mutual_icon_2,
  mutual_icon_3,
  mutual_icon_4,
  mutual_icon_5,
  equity_stock,
  feature_icon_1,
  feature_icon_2,
  feature_icon_3,
  mutal_benefit,
  bees_icon_1,
  bees_icon_2,
  bees_icon_3,
  invest_icon_1,
  invest_icon_2,
  platform_img_01,
  store_qr,
  apple_store,
  play_store,
  futureoption_1,
  futureoption_2,
  futureoption_3,
  futureoption_4,
  investor_grevience_image,
  book_logo,
  mutual_fund_logo,
  global_investing_logo,
  coming_soon,
  evoting_icon,
  nri_banner,
  ap_pdf1,
  ap_pdf2,
  ap_pdf3,
  mutualfund_banner,
  mutualfund_banner_webp,
  mutual_bg
};
export default Assets;
