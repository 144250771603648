import Assets from "../Layouts/Assets";

export const DummyData = () => {
  const MemberBroker = [
    {
      image: Assets.member_broker_01,
    },
    {
      image: Assets.member_broker_02,
    },
    {
      image: Assets.member_broker_03,
    },
    {
      image: Assets.member_broker_04,
    },
    {
      image: Assets.member_broker_05,
    },
    {
      image: Assets.member_broker_06,
    },
  ];

  const choosefromoffering = [
    "equity",
    "Mutual Funds",
    "future & options",
    "fixed deposit",
    "bonds",
    "global investing",
    "Unlisted shares",
    "Investing With Registered Advisors",
  ];
  const homebannerslider = [
    {
      heading: "Invest In Future & Options",
      subheading: (
        <p>
          Trade in Future & Options @INR 20 Brokerage from your mobile and web
        </p>
      ),
      button: "open demat account",
      price: 0,
      pricecontent: "Equity Delivery",
      price2: 20,
      price2content: "Intraday, Futures & options",
      image: Assets.home_banner,
    },
    {
      heading: "Invest In Future & Options",
      subheading: (
        <p>
          Trade in Future & Options @INR 20 Brokerage from your mobile and web
        </p>
      ),
      button: "open demat account",
      price: 0,
      pricecontent: "Equity Delivery",
      price2: 20,
      price2content: "Intraday, Futures & options",
      image: Assets.home_banner,
    },
    {
      heading: "Invest In Future & Options",
      subheading: (
        <p>
          Trade in Future & Options @INR 20 Brokerage from your mobile and web
        </p>
      ),
      button: "open demat account",
      price: 0,
      pricecontent: "Equity Delivery",
      price2: 20,
      price2content: "Intraday, Futures & options",
      image: Assets.home_banner,
    },
    {
      heading: "Invest In Future & Options",
      subheading: (
        <p>
          Trade in Future & Options @INR 20 Brokerage from your mobile and web
        </p>
      ),
      button: "open demat account",
      price: 0,
      pricecontent: "Equity Delivery",
      price2: 20,
      price2content: "Intraday, Futures & options",
      image: Assets.home_banner,
    },
  ];

  const RecentBlogs = [
    {
      image: Assets.recentblog1,
      date: "February 18, 2022",
      heading: "Invest more in smallcases, now improved",
      text: "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.recentblog2,
      date: "February 18, 2022",
      heading:
        "Introducing horizon — Goal-based smallcases by windmill capital",
      text: "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.recentblog3,
      date: "February 18, 2022",
      heading: "Invest more in smallcases, now improved",
      text: "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
  ];

  const ContactFaqs = [
    {
      title: "How can I change or reset my trading password?",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with ",
    },
    {
      title: "How can I unblock my account?",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with ",
    },
    {
      title:
        "How do I activate Commodities/Futures/Options segments in my account?",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with ",
    },
    {
      title: "How can I change or reset my trading password?",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with ",
    },
  ];

  const FaqTabList = [
    {
      title: "My Account",
    },
    {
      title: "Equity",
    },
    {
      title: "Equity SIP",
    },
    {
      title: "Mutual Funds",
    },
    {
      title: "Gold",
    },
    {
      title: "Derivatives",
    },
    {
      title: "Commodity",
    },
    {
      title: "Currency",
    },
    {
      title: "Other Products",
    },
    {
      title: "NRI",
    },
  ];

  const DownloadContent = [
    {
      title: "Application Form Equity(Non Individual)",
    },
    {
      title: "Application Form Equity(Non Individual)",
    },
    {
      title: "Application Form Equity(Non Individual)",
    },
    {
      title: "Application Form Equity(Non Individual)",
    },
  ];

  const BlogPost = [
    {
      image: Assets.blog_img_1,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.blog_img_2,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.blog_img_3,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.blog_img_4,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.blog_img_5,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
    {
      image: Assets.blog_img_6,
      label: "Mutual Fund",
      date: "February 18, 2022",
      title: "Invest more in smallcases, now improved",
      content:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum",
    },
  ];

  const HeaderMenu = [
    {
      title: "About",
      slug: "/about-us",
    },
    {
      title: "Products",
      sub_menu: [
        {
          title: "Equity",
          icon: Assets.prodcuts_icon_01,
          slug: "/equity",
        },
        {
          title: "Mutual Fund",
          icon: Assets.prodcuts_icon_02,
          slug: "/mutual-funds",
        },
        {
          title: "Future & Option",
          icon: Assets.prodcuts_icon_03,
          slug: "/future-option",
        },
        {
          title: "Fixed Deposit",
          icon: Assets.prodcuts_icon_04,
          slug: "/fixed-deposit",
        },
        {
          title: "Bonds",
          icon: Assets.prodcuts_icon_05,
          slug: "/bonds",
        },
        {
          title: "Unlisted shares",
          icon: Assets.prodcuts_icon_06,
          slug: "/unlisted-shares",
        },
        {
          title: "Investing With Registered Advisors",
          icon: Assets.prodcuts_icon_08,
          slug: "/investing-with-registered-advisors",
        },
        {
          title: "Exchange traded funds",
          icon: Assets.prodcuts_icon_09,
          slug: "/exchange-traded-funds",
        },
        {
          title: "Global investing",
          icon: Assets.prodcuts_icon_06,
          slug: "/global-investing",
        },
      ],
    },
    {
      title: "Platforms",
      slug: "/platforms",
    },
    {
      title: "Attention investors",
      slug: "/attentioninvestors",
    },
    {
      title: "Contact us",
      slug: "/contact-us",
    },
  ];

  const AttentionInvesters = [
    {
      file: Assets.ap_pdf1,
      text: "NSE AP List"
    },
    {
      file: Assets.ap_pdf2,
      text: "NSE AP List"
    },
    {
      file: Assets.ap_pdf3,
      text: "MCX AP List"
    }
  ];

  const MutualFunds = {
    title: 'Invest In Mutual Funds',
    desc: 'You can open mutual fund account online using our Mobile APP – Box by Pentad.' ,
    image: Assets.mutualfund_banner,
    webp: Assets.mutualfund_banner_webp
  };

  return {
    homebannerslider,
    MemberBroker,
    choosefromoffering,
    RecentBlogs,
    ContactFaqs,
    FaqTabList,
    DownloadContent,
    BlogPost,
    HeaderMenu,
    AttentionInvesters,
    MutualFunds
  };
};
