import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  homeBanner: null,
  whyPentad: null,
  ourPlatform: null,
  memberBroker: null,
  chooseProduct:null,
};

export const getHomeBannerData = createAsyncThunk(
  "homePage/homeBanner",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/home/banner");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChooseProductData = createAsyncThunk(
  "homePage/chooseProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/our-offerings");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getWhyPentadData = createAsyncThunk(
  "homePage/whyPentad",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/home/about-section");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOurPlatformData = createAsyncThunk(
  "homePage/ourplatform",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/home/app-section");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMemberBrokerData = createAsyncThunk(
  "homePage/memberbroker",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/home/members");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homePageDataSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {},
  extraReducers: {
    [getHomeBannerData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getHomeBannerData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.homeBanner = action.payload?.data?.data?.details?.banner;
    },
    [getHomeBannerData.rejected]: (state) => {
      state.status = "failed";
    },

    [getChooseProductData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getChooseProductData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.chooseProduct = action.payload?.data?.data?.details;
    },
    [getChooseProductData.rejected]: (state) => {
      state.status = "failed";
    },

    [getWhyPentadData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getWhyPentadData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.whyPentad = action.payload?.data?.data?.details;
    },
    [getWhyPentadData.rejected]: (state) => {
      state.status = "failed";
    },

    [getOurPlatformData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getOurPlatformData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.ourPlatform = action.payload?.data?.data?.details;
    },
    [getOurPlatformData.rejected]: (state) => {
      state.status = "failed";
    },

    [getMemberBrokerData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getMemberBrokerData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.memberBroker = action.payload?.data?.data?.details?.list_items;
    },
    [getMemberBrokerData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default homePageDataSlice.reducer;
