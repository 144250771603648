import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import getAxiosInstance from '../../../apis'
import { useLocation } from "react-router-dom";

const CommonLayout = ({ children }) => {
  const [metaData,setMetaData]=useState();  
  const router = useLocation();  
  useEffect(()=>{
         getAxiosInstance.get(`/general-seo?type=page&slug=${router.pathname == '/' ? '/home' : router.pathname}`).then((response) => {
      setMetaData(response?.data)
    });  

    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', `https://www.pentadsecurities.com${router.pathname}`);
    }
  },[router.pathname])

  return (
    <>
      <Helmet>
        <title>{metaData?.title[0]}</title>
        <meta name="description" content={metaData?.description[0]}/>
        <meta property="og:type" content={metaData?.og_type}/>
        <meta property="og:title" content={metaData?.title[0]}/>
        <meta property="og:description" content={metaData?.description[0]}/>
        {/* <meta property="og:image" content={metaData?.og_image}/>
        <meta property="og:image:width" content="1200"/> */}
      </Helmet>
      <main className="site_main noscroll">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default CommonLayout;
