import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  postStatus: "idle",
  productFormStatus: "idle",
  investerFormStatus: "idle",
  NRIStatus: "idle",
  keyStatus: "idle",
  updationStatus: "idle",
  message: null,
  form_Key: null,
};

export const getFormKey = createAsyncThunk(
  "form/getFormKey",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await getAxiosInstance.get(`form/security/get_nonce`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const postFormData = createAsyncThunk(
  "form/contactUs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.post(
        "form/action/get-in-touch",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postProductFormData = createAsyncThunk(
  "form/ProductFormData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.post(
        "form/action/request-callback",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const investerFormData = createAsyncThunk(
  "form/investerFormData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.post(
        "form/action/investor-grievances",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const NRIFormData = createAsyncThunk(
  "form/NRIFormData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.post(
        "form/action/nri-callback",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdationFormData = createAsyncThunk(
  "form/updation",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.post(
        "form/action/nach-cancellation",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {},
  extraReducers: {
    [getFormKey.pending]: (state, action) => {
      state.keyStatus = "loading";
      state.message = null;
    },
    [getFormKey.fulfilled]: (state, action) => {
      state.keyStatus = "succeeded";
      state.form_Key = action?.payload?.data;
    },
    [getFormKey.rejected]: (state, action) => {
      state.keyStatus = "failed";
    },

    [postFormData.pending]: (state, action) => {
      state.postStatus = "loading";
      state.message = null;
    },
    [postFormData.fulfilled]: (state, action) => {
      state.postStatus = "succeeded";
    },
    [postFormData.rejected]: (state, action) => {
      state.postStatus = "failed";
    },

    [postProductFormData.pending]: (state, action) => {
      state.productFormStatus = "loading";
      state.message = null;
    },
    [postProductFormData.fulfilled]: (state, action) => {
      state.productFormStatus = "succeeded";
    },
    [postProductFormData.rejected]: (state, action) => {
      state.productFormStatus = "failed";
    },

    [investerFormData.pending]: (state, action) => {
      state.investerFormStatus = "loading";
      state.message = null;
    },
    [investerFormData.fulfilled]: (state, action) => {
      state.investerFormStatus = "succeeded";
    },
    [investerFormData.rejected]: (state, action) => {
      state.investerFormStatus = "failed";
    },
    [NRIFormData.pending]: (state, action) => {
      state.NRIStatus = "loading";
      state.message = null;
    },
    [NRIFormData.fulfilled]: (state, action) => {
      state.NRIStatus = "succeeded";
    },
    [NRIFormData.rejected]: (state, action) => {
      state.NRIStatus = "failed";
    },
    [UpdationFormData.pending]: (state, action) => {
      state.updationStatus = "loading";
      state.message = null;
    },
    [UpdationFormData.fulfilled]: (state, action) => {
      state.updationStatus = "succeeded";
    },
    [UpdationFormData.rejected]: (state, action) => {
      state.updationStatus = "failed";
    },
  },
});

export default formSlice.reducer;
