import { combineReducers } from "@reduxjs/toolkit";
import FooterDataSlice from "./slices/FooterDataSlice";
import HeaderDataSlice from "./slices/HeaderDataSlice";
import HomePageSlice from "./slices/HomePageSlice";
import RecentBlogsSlice from "./slices/RecentBlogsSlice";
import AboutUsSlice from "./slices/AboutUsSlice";
import BlogsDataSlice from "./slices/BlogsDataSlice";
import SingleBlogSlice from "./slices/SingleBlogSlice";
import ContactUsSlice from "./slices/ContactUsSlice";
import FAQSlice from "./slices/FAQSlice";
import DownloadSlice from "./slices/DownloadSlice";
import AttentionInvestersSlice from "./slices/AttentionInvestersSlice";
import TermsAndPolicySlice from "./slices/TermsAndPolicySlice";
import ProductsSlice from "./slices/ProductsSlice";
import PlatformSlice from "./slices/PlatformSlice";
import formSlice from "./slices/formSlice";
import kycPolicySlice from "./slices/KycPolicyBannerSlice";
import NRIInvestmentSlice from "./slices/NRIInvestmentSlice";
import UpdationProvisionSlice from "./slices/UpdationProvisionSlice";
import EscalationMatrix from "./slices/EscalationMatrixSlice";
import MutualFundSlice from "./slices/MutualFundSlice";
import InvesterGrievancesSlice from "./slices/InvesterGrievancesSlice";

const rootReducer = combineReducers({
  header: HeaderDataSlice,
  footer: FooterDataSlice,
  homePage: HomePageSlice,
  recentBlogs: RecentBlogsSlice,
  aboutUs: AboutUsSlice,
  blogs: BlogsDataSlice,
  singleBlog: SingleBlogSlice,
  contactUs: ContactUsSlice,
  faq: FAQSlice,
  downloads: DownloadSlice,
  attention: AttentionInvestersSlice,
  terms: TermsAndPolicySlice,
  products: ProductsSlice,
  platform: PlatformSlice,
  form: formSlice,
  kycpolicy: kycPolicySlice,
  nriinvestment: NRIInvestmentSlice,
  provisions: UpdationProvisionSlice,
  escalation: EscalationMatrix,
  mutualfund: MutualFundSlice,
  grievances:InvesterGrievancesSlice
});

export default rootReducer;
